<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4" lg="4">
        <h1>Dashboard Login</h1>
        <v-text-field
            label="Username"
            v-model="username"
            v-on:keyup.enter="login"/>
        <v-text-field
            type="password"
            label="Password"
            v-model="password"
            v-on:keyup.enter="login"/>
        <v-btn
            :loading="loading"
            @click="login"
            class="mt-2"
            color="primary"
            elevation="1">Login
        </v-btn>
      </v-col>
    </v-row>

    <v-row align="center" justify="center">
      <v-col cols="12" lg="4">
        <h2>OAuth</h2>

        <v-btn v-for="provider in providers" :key="provider.name" :href="provider.authorizationUrl">
          {{ provider.name }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    username: '',
    password: '',
    loading: false,
  }),
  methods: {
    login() {
      this.loading = true
      this.$store.state.ky.post('auth/login/', { json: { UserName: this.username, Password: this.password } })
          .json()
          .then(user => {
            this.$store.commit('setUser', user)
            this.$router.replace({ name: 'Energy' })
          }).catch(() => {
        this.loading = false
      })
    },
  },
  computed: {
    providers() {
      return Object.entries(this.$store.state.providers).map(([name, authorizationUrl]) => ({
        name,
        authorizationUrl,
      }))
    },
  },
}
</script>
